<template>
  <div class="wrap">
    <a-form-model
      ref="editGoodsForm"
      :model="formData"
      :rules="rules"
      v-bind="formItemLayout"
    >
      <a-form-model-item label="关联产品" prop="relatedProduct">
        <a-select 
          v-model="formData.relatedProduct"
          show-search
          :default-active-first-option="false"
          :filter-option="false"
          :not-found-content="null"
          @search="handleSearch"
          @change="handleChange"
          placeholder="请输入关联产品"
          style="width: 80%"
          :disabled="isEdit"
        >
          <a-select-opt-group v-for="itemGroup in dataSel" :key="itemGroup.groupName">
            <span slot="label">{{itemGroup.groupName}}</span>
            <a-select-option :value="item.id" v-for="item in itemGroup.childrens" :key="item.id">
              {{item.name}}
            </a-select-option>
          </a-select-opt-group>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="商品名称" prop="name">
        <a-input 
          v-model="formData.name"           
          style="width: 80%;"
          placeholder="请输入商品名称"
        >
        </a-input>
      </a-form-model-item>
      <a-form-model-item label="商品编码" prop="code">
        <a-input
          v-model="formData.code"           
          style="width: 80%;"
          placeholder="请输入商品编码"
        >        
        </a-input>
      </a-form-model-item>
      <a-form-model-item label="商品型号">
        <a-input
          v-model="formData.type"           
          style="width: 80%;"
          placeholder="请输入商品型号"
        >        
        </a-input>
      </a-form-model-item>
      <a-form-model-item label="版本" prop="version">
        <a-select 
          v-model="formData.version" 
          style="width: 80%;"
          placeholder="请选择版本"
          :disabled="!formData.relatedProduct"
        >
          <a-select-option :value="item.bomId" v-for="item in curProduct.childVersions" :key="item.bomId">
            {{item.versionText}}
          </a-select-option>
        </a-select>
      </a-form-model-item>     
      <a-form-model-item label="分类名称">
        {{formData.categoryName}}
      </a-form-model-item> 
    </a-form-model>
  </div>
</template>
<script>
import { getBoms, getGoodsInfo } from "@/api/purchase.js";
import debounce from 'lodash/debounce';

export default {
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    goodsId: { 
      type: String, 
      default: () => "" 
    },
  },
  data() {
    return {
      formItemLayout: {
        labelCol: {
          span: 6 
        },
        wrapperCol: {
          span: 18
        },
      },
      formData: {
        relatedProduct: undefined,
        name: '',
        code: '',
        type: '',
        version: undefined,
        categoryName: '',
      },
      rules: {
        relatedProduct: [
          { required: true, message: '必填项', trigger: 'blur' },
        ],
        name: [
          { required: true, message: '必填项', trigger: 'blur' },
        ],
        code: [
          { required: true, message: '必填项', trigger: 'blur' },
        ],
        version: [
          { required: true, message: '必填项', trigger: 'change' },
        ],
      },
      dataSel: [],    // 关联产品 下拉框数据
      curProduct: {}, // 选中的当前产品 数据对象
    };
  },
  created() {
    this.handleSearch = debounce(this.handleSearch, 600);

    if(this.isEdit) {
      this.getEditData();
    }
  },
  methods: {
    handleSearch(value) {
      console.log("handleSearchSel", value);

      let params = {
        name: value,
      }
      
      getBoms(params).then(res => {
        console.log('getBoms', res)
        this.dataSel = res;
      });
    },
    handleChange(e) {
      console.log("handleChangeSel", e);
      this.getCurProduct(e);
    },
    getCurProduct(value) {
      this.dataSel.forEach(itemGrop => {
        itemGrop.childrens.forEach(item => {
          if(item.id == value) {
            this.curProduct = item;
            this.formData.categoryName = itemGrop.groupName;
            this.formData.version = undefined;
          }
        })
      })
    },
    getEditData() {
      getGoodsInfo(this.goodsId).then((res) => {
        console.log('getEditData', res)
        if(res&&res.bomId) {
          this.formData.relatedProduct = res.materialItemId;
          this.dataSel = [{
            groupName: res.goodsClassificationName,
            childrens: [
              {
                "id": res.materialItemId,
                "name": res.materialName,
              }              
            ]
          }];

          this.formData.name = res.goodsName;
          this.formData.code = res.goodsCode;
          this.formData.type = res.marking;
          
          this.formData.version = res.bomId;
          this.curProduct.childVersions = res.versionsList;

          this.formData.categoryName = res.goodsClassificationName;
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 24px;
  color: #999;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
.wrap {
  .ant-form-item {
    margin-bottom: 12px;
  }
}
</style>
