<template>
  <div class="bgStyle wrapper">
    <div class="head">
      <a-row>
        <a-col :span="7" class="cond">
          <label style="width: 90px;">商品信息：</label>
          <a-input v-model="goodsInfo" style="width: 60%;" allowClear placeholder="商品编码、商品名称、规格型号" />
        </a-col>
        <a-col :span="5" class="cond">
          <label style="width: 50px;">状态：</label>
          <a-select
            v-model="goodsStatus"
            style="width:60%"
            allowClear
            placeholder="请选择状态"
          >
            <a-select-option value="1">已上架</a-select-option>
            <a-select-option value="2">已下架</a-select-option>
          </a-select>
        </a-col>
        <a-col :span="12" class="cond">
          <div>
            <a-button type="primary" @click="onSearch">查询</a-button>
          </div>
        </a-col>
      </a-row>
    </div>
    <a-row :style="{marginBottom: '12px'}">
      <a-col :span="24">
        <a-space>
          <a-button type="primary" @click="addGoods">添加商品</a-button>
        </a-space>
      </a-col>
    </a-row>   
    <a-table
      :columns="columns"
      :data-source="dataSource"
      :rowKey="(record) => record.id"
      :pagination="pagination"
      @change="handleTableChange"
    >
      <template slot="shelfStatus" slot-scope="text">
        {{ text | filterStatus }}
      </template>
      <template slot="operation" slot-scope="text, record">
        <a-dropdown>
          <!-- 1：上架 2：下架 -->
          <a-menu slot="overlay">
            <template v-if="record.shelfStatus == 1">
              <a-menu-item key="1" @click="toDetail(record)">
                商品管理
              </a-menu-item>
              <a-menu-item key="2" @click="soldOut(record)">
                商品下架
              </a-menu-item>
              <a-menu-item key="3" @click="openEdit(record)">
                商品编辑
              </a-menu-item>
            </template>
            <template v-else>
              <a-menu-item key="4" @click="soldAdd(record)">
                商品上架
              </a-menu-item>
            </template>
          </a-menu>
          <a> 操作 <a-icon type="down" /> </a>
        </a-dropdown>
      </template>
    </a-table>

    <a-modal
      v-model="visible"
      :title="tit"
      :maskClosable="false"
      :width="600"
      :destroyOnClose="true"
      @ok="handleOk"
    >
      <edit-goods-modal 
        ref="edit"
        :isEdit="isEdit"
        :goodsId="goodsId"
      />
    </a-modal> 
  </div>
</template>

<script>
import EditGoodsModal from "./components/EditGoodsModal.vue";
import { getList, downShelf, upShelf, create, update } from "@/api/purchase.js";

const columns = [
  {
    title: "商品名称",
    dataIndex: "goodsName",
    ellipsis: true,
  },
  {
    title: "商品编码",
    dataIndex: "goodsCode",
    ellipsis: true,
  },
  {
    title: "商品型号",
    dataIndex: "marking",
    ellipsis: true,
  },
  {
    title: "分类",
    dataIndex: "materialClassificationName",
    ellipsis: true,
  },
  {
    title: "关联产品",
    dataIndex: "materialName",
    ellipsis: true,
  },
  {
    title: "版本",
    dataIndex: "version",
    ellipsis: true,
  },
  {
    title: "单位",
    dataIndex: "unitName",
  },
  {
    title: "状态",
    dataIndex: "shelfStatus",
    ellipsis: true,
    scopedSlots: { customRender: "shelfStatus" },
  },
  {
    title: "操作",
    dataIndex: "operation",
    scopedSlots: { customRender: "operation" },
    width: 100,
  },
];

export default {
  name: "SalesManageIndex",
  components: {
    EditGoodsModal
  },
  data() {
    return {
      dataSource: [],
      columns,
      pagination: {
        pageSize: 10,
        total: 0,
        current: 1,
        skipCount: 0,
      },

      goodsInfo: '',
      goodsStatus: undefined,

      visible: false,
      tit: '添加商品',
      isEdit: false,
      goodsId: '',
    };
  },
  filters: {
    filterStatus(val) {
      if(val == 1) {
        return '已上架';
      } else if(val == 2) {
        return '已下架';
      } else {
        return '';
      }
    }
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      let pageSize = this.pagination.pageSize;
      let skipCount = (this.pagination.current - 1) * pageSize;

      let params = {
        filter: this.goodsInfo,
        shelfStatus: this.goodsStatus,
        skipCount,
        maxResultCount: pageSize,
      };

      getList(params).then((res) => {
        console.log("getList", res);
        this.dataSource = res.items;
        this.pagination.total = res.totalCount;
        this.skipCount = params.skipCount;
      });
    },
    handleTableChange(pagination) {
      this.pagination.current = pagination.current;
      this.getData();
    },
    onSearch() {
      this.pagination.current = 1;
      this.getData();
    },    
    toDetail(record) {
      this.$router.push({
        name: "GoodsManageDetail",
        params: {
          goodsId: record.id,
          materialItemId: record.materialId,
        },
      });
    },
    soldOut(record) {
      const _this = this;
      this.$confirm({
        title: "确认下架商品：" + record.goodsName + "?",
        onOk() {
          let params = {
            id: record.id,
          }
          downShelf(params).then(() => {
            _this.$message.success("商品下架成功");

            _this.pagination.current = 1;
            _this.getData();
          });
        },
      });
    },
    soldAdd(record) {
      const _this = this;
      this.$confirm({
        title: "确认上架商品：" + record.goodsName + "?",
        onOk() {
          let params = {
            id: record.id,
          }
          upShelf(params).then(() => {
            _this.$message.success("商品上架成功");

            _this.pagination.current = 1;
            _this.getData();
          });
        },
      });
    },
    addGoods() {
      this.tit = '添加商品';
      this.isEdit = false;
      this.visible = true;
    },
    openEdit(record) {
      this.goodsId = record.id;
      this.tit = '编辑商品';
      this.isEdit = true;
      this.visible = true;
    },
    handleOk() {
      this.$refs.edit.$refs['editGoodsForm'].validate(valid => {
        if (valid) {
          console.log(this.$refs.edit.formData)
          let formData = this.$refs.edit.formData || {};
          let params = {
            goodsName: formData.name,
            goodsCode: formData.code,
            marking: formData.type,
            bomId: formData.version,
          };

          if(this.isEdit) {
            params.id = this.goodsId;
            update(params).then((res) => {
              console.log("update", res);
              this.$message.success("编辑商品成功");
              this.onSearch();
              this.visible = false;            
            });
          } else {
            create(params).then((res) => {
              console.log("create", res);
              this.$message.success("添加商品成功");
              this.onSearch();
              this.visible = false;            
            });
          }          
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  background: #FFF !important;
  .head {
    margin-bottom: 16px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 22px;
    > .cond {
      display: flex;
      align-content: center;
    }
  }
}
</style>
